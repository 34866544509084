
import { defineComponent } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "invite-friends-modal",
  components: {},
  async mounted() {
    this.init();
  },
  setup() {
    const smeHelpDeskFormValidator = Yup.object({
      smeHelpDeskData: Yup.object({
        companyName: Yup.string().required().label("Company Name"),
        companyId: Yup.string().required().label("Company Registration No."),
        sector: Yup.string().required().label("Sector"),
        reason: Yup.string().required().label("Reason"),
        type: Yup.string().required().label("Type"),
        agree: Yup.string()
          .test(
            "",
            "You must agree with the Term and Condirion to proceed!",
            (value) => value === "1"
          )
          .required("You must agree with the Term and Condirion to proceed!"),
        // applicantName: Yup.string().required().label("ApplicantName"),
        // customerId: Yup.string()
        //   .required()
        //   .min(12)
        //   .max(12)
        //   // .matches(/[0-9]{12}/, "MyKad No must contain 12 digit numbers.")
        //   .label("MyKad No"),
        // phoneNum: Yup.string().required().label("Phone Number"),
        // email: Yup.string().required().label("Email"),
        // // loanNumber: Yup.string().required().label("Loan Number"),
      }),
    });
    const sectors = [
      {
        value: "Agriculture",
        label: "Petanian Asas/Agriculture",
      },
      {
        value: "Construction",
        label: "Pembinaan/Construction",
      },
      {
        value: "Manufacturing",
        label: "Pembuatan/Manufacturing",
      },
      {
        value: "Services",
        label: "Perkhidmatan/Services",
      },
      {
        value: "Trading",
        label: "Perdagangan/Trading",
      },
    ];

    const reasons = [
      {
        value: "Business ceased operation",
        label: "Operasi peniagaan dihentikan/Business ceased operation",
      },
      {
        value: "Revenue/sales drop",
        label: "Penurunan jualan /Revenue/sales drop",
      },
      {
        value: "Supply chain disruption",
        label: "Gangguan rataian bekalan perniagaan /Supply chain disruption",
      },
      {
        value: "Others",
        label: "Lain-lain/Others",
      },
    ];

    const types = [
      {
        value: "Defer monthly instalments for 3 months; or",
        label:
          "Menangguhkan ansuran bulanan selama 3 bulan/Defer monthly instalments for 3 months; or",
      },
      {
        value: "Reduce monthly instalments by 50% for 6 months.",
        label:
          "Mengurangkan ansuran bulanan sebanyak 50% selama 6 bulan./Reduce monthly instalments by 50% for 6 months.",
      },
      {
        value: "Others",
        label: "Lain-lain/Others",
      },
    ];

    const nameBanks = [
      { value: "0", label: "Affin Bank" },
      { value: "1", label: "Agrobank" },
      { value: "2", label: "Alliance" },
      { value: "3", label: "Ambank" },
      { value: "4", label: "Bank Islam" },
      { value: "5", label: "Bank Muamalat" },
      { value: "6", label: "Bank Rakyat" },
      { value: "7", label: "BSN" },
      { value: "8", label: "CIMB Bank" },
      { value: "9", label: "Hong Leong" },
      { value: "10", label: "Maybank" },
      { value: "11", label: "Public Bank" },
      { value: "12", label: "UOB" },
      { value: "13", label: "OCBC" },
      { value: "14", label: "Standard Chartered " },
      { value: "15", label: "CGC" },
    ];

    const financeTypes = [
      { value: "Hire Purchase", label: "Hire Purchase / Pembiayaan Automotif" },
      { value: "Overdraft", label: "Overdraft / Overdraf" },
      {
        value: "Revolving Credit",
        label: "Revolving Credit / Kredit Pusingan",
      },
      { value: "Term Loan", label: "Term Loan / Pembiayaan Berkala" },
    ];

    return {
      smeHelpDeskFormValidator,
      sectors,
      nameBanks,
      financeTypes,
      reasons,
      types,
    };
  },
  data() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const cstmr_id = route.params.id ?? null;
    const raForm_id = route.params.ra_form_id ?? null;

    const smeHelpDeskData = {
      companyName: "",
      sector: "",
      reason: "",
      customerId: "",
      companyId: "",
      type: "",
      applicantName: "",
      email: "",
      phoneNum: "",
      loanNumber: "",
      nameBank: "",
      finance: {
        a: {
          type: "",
          number: "",
        },
        b: {
          type: "",
          number: "",
        },
        c: {
          type: "",
          number: "",
        },
      },
      isAgree: false,
    };

    const creditors = [];

    const customer = {
      birthdate: "",
      company_id: 0,
      detail: {
        id: 0,
        user_id: 0,
        marital_status: "",
        alternate_contact_no: "",
        no_of_dependant: "",
        state: "",
        city: "",
        residential_status: "",
        employment_status: "",
        previous_gross_income: "",
        current_gross_income: "",
        other_loan_commitment: "",
        current_net_income: "",
        spouse_employment_status: "",
        spouse_income: "",
        urs_package: "",
        dmp_reference_number: "",
        approval_date: "",
        created_at: "",
        updated_at: "",
      },
      email: "",
      facilities: [],
      gender: "",
      media: [],
      name: "",
      nric: "",
      phone: "",
      package: "",
      net_worth_secured_liabilities: [],
      net_worth_secured_total: [],
      net_worth_unsecured_liabilities: [],
      net_worth_unsecured_total: [],
    };

    return {
      store,
      router,
      smeHelpDeskData,
      creditors,
      cstmr_id,
      raForm_id,
      customer,
    };
  },
  methods: {
    init() {
      this.store.dispatch(Actions.GET_CREDITORS).then(() => {
        this.creditors = this.store.getters.getCreditorsData;
      });

      this.store.dispatch(Actions.GET_CUSTOMER, this.cstmr_id).then(() => {
        this.customer = this.store.getters.getCustomerData;
        this.smeHelpDeskData.applicantName = this.customer.name;
        this.smeHelpDeskData.customerId = this.customer.nric;
        this.smeHelpDeskData.phoneNum = this.customer.phone;
        this.smeHelpDeskData.email = this.customer.email;
      });

      this.store.dispatch(Actions.GET_RA_FORM, this.raForm_id).then(() => {
        let raFormData = this.store.getters.getRaFormData;

        this.smeHelpDeskData = raFormData.raw.smeHelpDeskData;

        for (let i = 0; i < this.sectors.length; i++) {
          if (this.sectors[i].value === this.smeHelpDeskData.sector) {
            this.smeHelpDeskData.sector = this.sectors[i].label;
          }
        }

        for (let i = 0; i < this.creditors.length; i++) {
          if (this.creditors[i].id === this.smeHelpDeskData.nameBank) {
            this.smeHelpDeskData.nameBank = this.creditors[i].ParameterValue;
          }
        }

        for (let i = 0; i < this.financeTypes.length; i++) {
          if (
            this.financeTypes[i].value === this.smeHelpDeskData.finance.a.type
          ) {
            this.smeHelpDeskData.finance.a.type = this.financeTypes[i].label;
          }

          if (
            this.financeTypes[i].value === this.smeHelpDeskData.finance.b.type
          ) {
            this.smeHelpDeskData.finance.b.type = this.financeTypes[i].label;
          }

          if (
            this.financeTypes[i].value === this.smeHelpDeskData.finance.c.type
          ) {
            this.smeHelpDeskData.finance.c.type = this.financeTypes[i].label;
          }
        }

        for (let i = 0; i < this.types.length; i++) {
          if (this.types[i].value === this.smeHelpDeskData.type) {
            this.smeHelpDeskData.type = this.types[i].label;
          }
        }

        for (let i = 0; i < this.reasons.length; i++) {
          if (this.reasons[i].value === this.smeHelpDeskData.reason) {
            this.smeHelpDeskData.reason = this.reasons[i].label;
          }
        }
      });
    },
    onPrint() {
      window.print();
    },
  },
});
